import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { MdMailOutline } from "react-icons/md";
import team from "./team.json";
import { AboutFooter } from "components/About/AboutFooter";

interface User {
  fullname: string;
  avatarUrl: string;
  title: string;
  email: string;
}

interface UserCardProps {
  user: User;
}

const PageContainer = styled.div`
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding: 0;
  }
`;

const TitleText = styled.h1`
  color: black;
  font-size: 36px;
  line-height: 44px;
  font-weight: 535;
  text-align: center;
  margin: 0 0 24px;
  background-clip: text;
  -webkit-background-clip: text;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 19px;
    line-height: 27px;
    margin-top: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    font-size: 32px;
    line-height: 48px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  height: 450px;
  width: 100%;
  margin-bottom: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PageBody = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    padding-left: 400px;
    padding-right: 400px;
  }
`;

const StyledUserCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;

  &:hover .user-name {
    color: #8d9300;
  }

  &:hover .mail-icon {
    display: inline;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 194px;
  height: 194px;
  object-fit: cover;
  margin-bottom: 16px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #3b3e4b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserName = styled.span`
  margin: 0;
  color: #000000;
  margin-bottom: 4px;
  &.user-name {
    transition: color 0.3s ease;
  }
`;

const MailIcon = styled(MdMailOutline)`
  display: none;
  margin-left: 8px;
  &.mail-icon {
    color: #8d9300;
  }
`;

const TeamCardWrapper = styled.div`
  display: grid;
  gap: 20px;
  margin: 0 auto;
  margin-bottom: 180px;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const TextContainer = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 64px;
  color: #000000;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 18px;
    margin-bottom: 60px;
  }
`;

const UserCard: React.FC<UserCardProps> = ({ user }) => (
  <StyledUserCard>
    <Avatar src={user.avatarUrl} alt={`Avatar of ${user.fullname}`} />
    <StyledLink href={`mailto:${user.email}`}>
      <UserName className="user-name">{user.fullname}</UserName>
      <MailIcon className="mail-icon" />
    </StyledLink>
    <p style={{ margin: "0", color: "#3E3B4B" }}>{user.title}</p>
  </StyledUserCard>
);

export default function Team() {
  return (
    <PageContainer>
      <TitleText>Our Team</TitleText>
      <PageBody>
        <div
          style={{
            fontSize: "24px",
            textAlign: "center",
            marginBottom: "64px",
          }}
        >
          <TextContainer>
            Working to bring you fair trade, transparency and traceability in
            eco-commodities
          </TextContainer>
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
              marginBottom: "100px",
              fontSize: "24px",
              color: "#3B3E4B",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                color: "#8D9300",
                fontSize: "16px",
                fontWeight: 400,
              }}
              href="mailto:info@gaiaswap.io"
            >
              Contact us to find out more!
            </a>
          </div>
        </div>
        <TeamCardWrapper>
          {team.map((user, index) => (
            <UserCard key={index} user={user} />
          ))}
        </TeamCardWrapper>
      </PageBody>
      <AboutFooter />
    </PageContainer>
  );
}
