import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { AboutFooter } from "components/About/AboutFooter";

const PageContainer = styled.div`
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding: 0;
  }
`;

const TitleText = styled.h1`
  color: black;
  font-size: 36px;
  line-height: 44px;
  font-weight: 535;
  text-align: center;
  margin: 0 0 24px;
  background-clip: text;
  -webkit-background-clip: text;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 19px;
    line-height: 27px;
    margin-top: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    font-size: 32px;
    line-height: 48px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  height: 450px;
  width: 100%;
  margin-bottom: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: 200px;
    margin-bottom: 40px;
  }
`;

const PageBody = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    padding-left: 400px;
    padding-right: 400px;
  }
`;

const TextContainer = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 64px;
  color: #000000;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 18px;
    margin-bottom: 60px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #040404;
  width: 60px;
  margin: 0 auto;
  margin-bottom: 100px;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-bottom: 60px;
  }
`;

const BodyText = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 180px;
  text-align: center;
  color: #3B3E4B;

   @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 14px;
  }
`;

export default function Mission() {
  return (
    <PageContainer>
      <TitleText>Mission Statement</TitleText>
      <ImageContainer>
        <img src="/images/landingpage/Mission.png" />
      </ImageContainer>
      <PageBody>
        <TextContainer>
          GaiaSwap is a decentralised blockchain-based platform built on an
          advanced protocol that amplifies the liquidity of the eco-commodities
          market whilst ensuring transparency, trust and sustainability in every
          transaction.
        </TextContainer>
        <Divider />
        <BodyText
        >
          <p>
            Empowering its participants to exchange internationally recognised
            eco-commodities for each other and/or fiat currencies, GaiaSwap’s
            peer-to-peer transactions are governed by smart contracts, utilising
            a fair and automated pricing mechanism. The platform’s price
            optimisation feature reflects an eco-commodity’s fair market value
            and accounts for its underlying attributes and co-benefits such as
            environmental, social and other Sustainable Development Goal (SDG)
            impacts. GaiaSwap revolutionises the traditional market by
            democratising market entry and removing the need for intermediaries,
            stimulating a robust market and maximising efficiency for all
            participants in the system.
          </p>
          <p>
            Domestic and international scalability are powered by GaiaSwap’s
            market-making and advanced data analytic capabilities. Domestic
            markets can be set up in compliance with a country’s governmental
            regulations, with vital analytics tracked and shared with national
            registries to facilitate legal compliance. As new crossborder
            bilateral agreements are introduced across the globe, GaiaSwap will
            evolve as the market leader for eco-commodities trade.
          </p>
        </BodyText>
      </PageBody>
      <AboutFooter />
    </PageContainer>
  );
}
