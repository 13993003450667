import React, { useState } from "react";
import { BREAKPOINTS } from "theme";
import styled from "styled-components";
import MailchimpForm from "components/Mailchimp/signup";

// Styled components
const FullWidthSection = styled.section`
  width: 100%;
  text-align: center;
  padding: 80px 20px;
  box-sizing: border-box;
  background: #f3f2eb;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #1a1e29;
  font-weight: 500;
  margin-bottom: 8px;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 24px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 32px;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #3b3e4b;
  margin-bottom: 20px;
`;

const SignUpButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #3b3e4b;
  font-weight: 500;
  cursor: pointer;
  border-radius: 100px;
  background: #c2e950;
  border: none;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 32px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputGroup = styled.div`
  flex: 1;
  margin-right: 10px;
  margin-bottom: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
`;

const SubmitButton = styled.button`
  background-color: #000;
  color: rgb(206, 250, 64);
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  width: 33%;
`;

interface FormData {
  firstName: string;
  lastName: string;
  designation: string;
  companyName: string;
  workEmail: string;
  contactNumber: string;
}

const initialFormData: FormData = {
  firstName: "",
  lastName: "",
  designation: "",
  companyName: "",
  workEmail: "",
  contactNumber: "",
};

// FullWidthComponent
const FullWidthComponent: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState<"idle" | "success" | "error">("idle");
  const [formData, setFormData] = useState(initialFormData);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setTouched({ ...touched, [name]: true });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      designation: formData.designation,
      companyName: formData.companyName,
      contactNumber: formData.contactNumber,
      workEmail: formData.workEmail,
    };

    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus("success");
        alert("Signup successful!");
      } else {
        console.error("API ERROR:", data.error);
        setStatus("error");
        alert(`Signup failed: ${data.error}`);
      }
    } catch (error) {
      console.error("NETWORK ERROR:", error);
      setStatus("error");
      alert("Network error, please try again later.");
    } finally {
      setLoading(false);
      setModalOpen(false); // Close the modal
    }
  };

  return (
    <FullWidthSection>
      <Title>Want to know more?</Title>
      <SubTitle>
        We are currently welcoming participants for our beta testing phase.
        Click to sign up!
      </SubTitle>
      <SignUpButton
        onClick={() =>
          window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSesl5Y7QddaUoweOYVPCdU0Zro6-w5nNSXYTY0o5bUdhOUXHA/viewform?usp=sf_link",
            "_blank"
          )
        }
      >
        Sign Up
      </SignUpButton>
      {isModalOpen && (
        <ModalBackdrop onClick={() => setModalOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <MailchimpForm onClose={() => setModalOpen(false)} />
          </ModalContent>
        </ModalBackdrop>
      )}
    </FullWidthSection>
  );
};

export default FullWidthComponent;
