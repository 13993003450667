import { useInfoExplorePageEnabled } from "featureFlags/flags/infoExplore";
import { useInfoPoolPageEnabled } from "featureFlags/flags/infoPoolPage";
import { useAtom } from "jotai";
import { lazy, ReactNode, Suspense, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { shouldDisableNFTRoutesAtom } from "state/application/atoms";
import { SpinnerSVG } from "theme/components";
import { isBrowserRouterEnabled } from "utils/env";

// High-traffic pages (index and /swap) should not be lazy-loaded.
import Landing from "./Landing";
import Mission from "./Landing/mission";
import Technology from "./Landing/technology";
import Swap from "./Swap";
import Team from "./Landing/team";

const AddLiquidityWithTokenRedirects = lazy(
  () => import("pages/AddLiquidity/redirects")
);
const MarketPlace = lazy(() => import("../components/MarketPlace"));
const NotFound = lazy(() => import("pages/NotFound"));
const Pool = lazy(() => import("pages/Pool"));
const PositionPage = lazy(() => import("pages/Pool/PositionPage"));
const ProjectDetailsPage = lazy(() => import("pages/projects/projectDetails"));
const CreditDetailsPage = lazy(() => import("pages/projects/creditDetails"));
const RemoveLiquidityV3 = lazy(() => import("pages/RemoveLiquidity/V3"));

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
);

interface RouterConfig {
  browserRouterEnabled?: boolean;
  hash?: string;
  infoExplorePageEnabled?: boolean;
  infoPoolPageEnabled?: boolean;
  shouldDisableNFTRoutes?: boolean;
}

/**
 * Convenience hook which organizes the router configuration into a single object.
 */
export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled();
  const { hash } = useLocation();
  const infoPoolPageEnabled = useInfoPoolPageEnabled();
  const infoExplorePageEnabled = useInfoExplorePageEnabled();
  const [shouldDisableNFTRoutes] = useAtom(shouldDisableNFTRoutesAtom);
  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
      infoExplorePageEnabled,
      infoPoolPageEnabled,
      shouldDisableNFTRoutes: Boolean(shouldDisableNFTRoutes),
    }),
    [
      browserRouterEnabled,
      hash,
      infoExplorePageEnabled,
      infoPoolPageEnabled,
      shouldDisableNFTRoutes,
    ]
  );
}

export interface RouteDefinition {
  path: string;
  nestedPaths: string[];
  enabled: (args: RouterConfig) => boolean;
  getElement: (args: RouterConfig) => ReactNode;
}

// Assigns the defaults to the route definition.
function createRouteDefinition(
  route: Partial<RouteDefinition>
): RouteDefinition {
  return {
    getElement: () => null,
    enabled: () => true,
    path: "/",
    nestedPaths: [],
    // overwrite the defaults
    ...route,
  };
}

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: "/",
    getElement: () => <Landing />,
  }),
  createRouteDefinition({
    path: "/mission",
    getElement: () => <Mission />,
  }),
  createRouteDefinition({
    path: "/technology",
    getElement: () => <Technology />,
  }),
  createRouteDefinition({
    path: "/team",
    getElement: () => <Team />,
  }),
  // createRouteDefinition({
  //   path: "/explore",
  //   nestedPaths: [":tab", ":chainName"],
  //   getElement: () => <RedirectExplore />,
  //   enabled: (args) => Boolean(args.infoExplorePageEnabled),
  // }),
  // createRouteDefinition({
  //   path: "/explore",
  //   nestedPaths: [":tab/:chainName"],
  //   getElement: () => <Explore />,
  //   enabled: (args) => Boolean(args.infoExplorePageEnabled),
  // }),
  // createRouteDefinition({
  //   path: "/explore/tokens/:chainName/:tokenAddress",
  //   getElement: () => <TokenDetails />,
  //   enabled: (args) => Boolean(args.infoExplorePageEnabled),
  // }),
  // createRouteDefinition({
  //   path: "/tokens",
  //   getElement: (args) => {
  //     return args.infoExplorePageEnabled ? (
  //       <Navigate to="/explore/tokens" replace />
  //     ) : (
  //       <Explore />
  //     );
  //   },
  // }),
  // createRouteDefinition({
  //   path: "/tokens/:chainName",
  //   getElement: (args) => {
  //     return args.infoExplorePageEnabled ? <RedirectExplore /> : <Explore />;
  //   },
  // }),
  // createRouteDefinition({
  //   path: "/tokens/:chainName/:tokenAddress",
  //   getElement: (args) => {
  //     return args.infoExplorePageEnabled ? (
  //       <RedirectExplore />
  //     ) : (
  //       <TokenDetails />
  //     );
  //   },
  // }),
  // createRouteDefinition({
  //   path: "explore/pools/:chainName/:poolAddress",
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <PoolDetails />
  //     </Suspense>
  //   ),
  //   enabled: (args) =>
  //     Boolean(args.infoExplorePageEnabled && args.infoPoolPageEnabled),
  // }),
  // createRouteDefinition({
  //   path: "/vote/*",
  //   getElement: () => (
  //     <Suspense fallback={<LazyLoadSpinner />}>
  //       <Vote />
  //     </Suspense>
  //   ),
  // }),
  // createRouteDefinition({
  //   path: "/create-proposal",
  //   getElement: () => <Navigate to="/vote/create-proposal" replace />,
  // }),
  createRouteDefinition({
    path: "/send",
    getElement: () => (
      <Navigate to={{ ...location, pathname: "/swap" }} replace />
    ),
  }),
  createRouteDefinition({ path: "/swap", getElement: () => <Swap /> }),
  createRouteDefinition({
    path: "/marketplace",
    getElement: () => <MarketPlace />,
  }),
  // createRouteDefinition({
  //   path: "/pool/v2/find",
  //   getElement: () => <PoolFinder />,
  // }),
  // createRouteDefinition({ path: "/pool/v2", getElement: () => <PoolV2 /> }),
  // createRouteDefinition({ path: "/pool", getElement: () => <Pool /> }),
  // createRouteDefinition({
  //   path: "/pool/:tokenId",
  //   getElement: () => <PositionPage />,
  // }),
  // createRouteDefinition({
  //   path: "/pools/v2/find",
  //   getElement: () => <PoolFinder />,
  // }),
  // createRouteDefinition({ path: "/pools/v2", getElement: () => <PoolV2 /> }),
  createRouteDefinition({ path: "/pools", getElement: () => <Pool /> }),
  createRouteDefinition({
    path: "/pools/:tokenId",
    getElement: () => <PositionPage />,
  }),
  createRouteDefinition({
    path: "/projects/:ecoCommodity",
    getElement: () => <ProjectDetailsPage />,
  }),
  createRouteDefinition({
    path: "/projects/credits/:creditName",
    getElement: () => <CreditDetailsPage />,
  }),

  // createRouteDefinition({
  //   path: "/add/v2",
  //   nestedPaths: [":currencyIdA", ":currencyIdA/:currencyIdB"],
  //   getElement: () => <AddLiquidityV2WithTokenRedirects />,
  // }),
  createRouteDefinition({
    path: "/add",
    nestedPaths: [
      ":currencyIdA",
      ":currencyIdA/:currencyIdB",
      ":currencyIdA/:currencyIdB/:feeAmount",
      ":currencyIdA/:currencyIdB/:feeAmount/:tokenId",
    ],
    getElement: () => <AddLiquidityWithTokenRedirects />,
  }),
  // createRouteDefinition({
  //   path: "/remove/v2/:currencyIdA/:currencyIdB",
  //   getElement: () => <RemoveLiquidity />,
  // }),
  createRouteDefinition({
    path: "/remove/:tokenId",
    getElement: () => <RemoveLiquidityV3 />,
  }),
  // createRouteDefinition({
  //   path: "/migrate/v2",
  //   getElement: () => <MigrateV2 />,
  // }),
  // createRouteDefinition({
  //   path: "/migrate/v2/:address",
  //   getElement: () => <MigrateV2Pair />,
  // }),
  // createRouteDefinition({
  //   path: "/nfts",
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <NftExplore />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  // }),
  // createRouteDefinition({
  //   path: "/nfts/asset/:contractAddress/:tokenId",
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Asset />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  // }),
  // createRouteDefinition({
  //   path: "/nfts/profile",
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Profile />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  // }),
  // createRouteDefinition({
  //   path: "/nfts/collection/:contractAddress",
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Collection />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  // }),
  // createRouteDefinition({
  //   path: "/nfts/collection/:contractAddress/activity",
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Collection />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  // }),
  createRouteDefinition({
    path: "*",
    getElement: () => <Navigate to="/not-found" replace />,
  }),
  createRouteDefinition({ path: "/not-found", getElement: () => <NotFound /> }),
];
