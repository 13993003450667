import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { AboutFooter } from "components/About/AboutFooter";

const PageContainer = styled.div`
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding: 0;
  }
`;

const TitleText = styled.h1`
  color: black;
  font-size: 36px;
  line-height: 44px;
  font-weight: 535;
  text-align: center;
  margin: 0 0 24px;
  background-clip: text;
  -webkit-background-clip: text;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 19px;
    line-height: 27px;
    margin-top: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    font-size: 32px;
    line-height: 48px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  height: 450px;
  width: 100%;
  margin-bottom: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: 200px;
    margin-bottom: 40px;
  }
`;

const PageBody = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    padding-left: 400px;
    padding-right: 400px;
  }
`;

const TextContainer = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 64px;
  color: #000000;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 18px;
    margin-bottom: 60px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #040404;
  width: 60px;
  margin: 0 auto;
  margin-bottom: 100px;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-bottom: 60px;
  }
`;

const BodyText = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 180px;
  text-align: center;
  color: #3b3e4b;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 14px;
  }
`;

export default function Technology() {
  return (
    <PageContainer>
      <TitleText>Our Technology</TitleText>
      <ImageContainer>
        <img src="/images/landingpage/Tech.png" />
      </ImageContainer>
      <PageBody>
        <TextContainer>
          GaiaSwap uses an Automated Market Maker (AMM) protocol to facilitate
          transactions on a constant market-making algorithm. Smart contracts
          govern the exchange process and allow users to trade eco-commodities
          without the need for a centralised exchange or a central authority to
          process trades.
        </TextContainer>
        <Divider />
        <BodyText>
          <p>
            The AMM model replaces the conventional central limit order book
            method of exchange, where buyers and sellers create orders organised
            by price level and regulated by demand shifts. Alternatively, AMMs
            create liquidity pools of two assets where trade shifts their
            relative prices and determines a new market rate for both - buyers
            and sellers trade with the pool instead of orders left by other
            parties.
          </p>
          <p>
            Based on market-leading application Uniswap’s open source
            technology, GaiaSwap is powered by smart contracts on the blockchain
            that enable users to directly interact with the platform without the
            need for intermediaries, traditional order book or matching systems.
            Smart contracts are responsible for determining the platform’s
            eco-commodity exchange rates and ensure secure trading without the
            risk of scams or fraud.
          </p>
          <p>
            GaiaSwap will leverage emerging blockchain technologies (such as
            layer-2 Ethereum scaling solutions) to ensure optimised trading
            conditions such as fast transaction processing, low and predictable
            transaction fees and consistent pricing.
          </p>
        </BodyText>
      </PageBody>
      <AboutFooter />
    </PageContainer>
  );
}
