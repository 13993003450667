import { AboutFooter } from "components/About/AboutFooter";
import { useAccountDrawer } from "components/AccountDrawer";
import { parse } from "qs";
import { useEffect, useMemo, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "state/hooks";
import styled, { css } from "styled-components";
import { BREAKPOINTS } from "theme";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { TRANSITION_DURATIONS } from "theme/styles";
import LandingPageContentSection from "components/LandingPageContentSection";
import SignupSection from "./signup";

const PageContainer = styled.div`
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding: 0;
  }
`;

const TitleText = styled.h1<{ isDarkMode: boolean }>`
  color: #1a1e29;
  font-size: 36px;
  line-height: 44px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 24px;
  background-clip: text;
  -webkit-background-clip: text;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 19px;
    line-height: 27px;
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 32px;
    line-height: 48px;
  }
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 36px;
    line-height: 48px;
  }
`;

const LandingHeroContainer = styled.div`
  width: 1070px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-top: 80px;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    width: 270px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 558px;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    width: 1070px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
`;

const Header = styled.div`
  margin: 0 auto;
  margin-bottom: 40px;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    width: 98%;
    margin-bottom: 10px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 80%;
  }
`;

const aboutUsData = [
  {
    imageURL: "/images/landingpage/About-Mission-1@2x.png",
    Title: "Mission Statement",
    Subtitle:
      "Combining climate action with digital innovation to revolutionise the eco-commodities market",
    learnMoreURL: "/#/mission",
  },
  {
    imageURL: "/images/landingpage/About-Mission-2@2x.png",
    Title: "Our Technology",
    Subtitle:
      "Utilising the best of blockchain to advance as market leader for eco-commodities trade",
    learnMoreURL: "/#/technology",
  },
  {
    imageURL: "/images/landingpage/About-Mission-3@2x.png",
    Title: "Meet the Team",
    Subtitle:
      "Working to bring fair trade and delightful user experience to the eco-commodities space",
    learnMoreURL: "/#/team",
  },
];

export default function Landing() {
  const swapSectionRef = useRef<HTMLDivElement>(null);
  const tradingPairsSectionRef = useRef<HTMLDivElement>(null);
  const tokenDiscoverySectionRef = useRef<HTMLDivElement>(null);
  const isDarkMode = useIsDarkMode();
  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);
  const [accountDrawerOpen] = useAccountDrawer();
  const location = useLocation();

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (accountDrawerOpen) {
  //     setTimeout(() => {
  //       navigate("/swap");
  //     }, TRANSITION_DURATIONS.fast);
  //   }
  // }, [accountDrawerOpen, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get("section");

    console.log("section", section);

    if (section) {
      const sectionRef =
        section === "swapSection"
          ? swapSectionRef
          : section === "poolsSections"
          ? tradingPairsSectionRef
          : section === "discoverySection"
          ? tokenDiscoverySectionRef
          : null;

      console.log("sectionRef outside", sectionRef);
      if (sectionRef && sectionRef.current) {
        console.log("sectionRef", sectionRef);
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <PageContainer data-testid="landing-page">
        <LandingHeroContainer>
          <Header>
            <TitleText isDarkMode={isDarkMode}>
              Accelerating climate action by removing market frictions in
              eco-commodity trade
            </TitleText>
          </Header>
          <ImageContainer>
            <img
              style={{ maxWidth: "100%" }}
              src="/images/landingpage/Gaia-landscape-1.png"
            />
          </ImageContainer>
        </LandingHeroContainer>
        <LandingPageContentSection
          Title="About Us"
          SubTitle="GaiaSwap is a decentralised blockchain-based platform that amplifies the liquidity of the eco-commodities market whilst ensuring transparency, trust and sustainability in every transaction"
          threeColumnContent={aboutUsData}
          backgroundColor="#F9F9F9"
        />
        <LandingPageContentSection
          ref={swapSectionRef}
          Title="Swap eco-commodity credits for offset or trade"
          SubTitle="Empowering participants to exchange internationally recognised eco-commodity credits for each other and/or fiat currencies, our peer-to-peer transactions are governed by smart contracts, utilising a fair and automated pricing mechanism"
          backgroundColor="#FFF"
          imageURL="/images/landingpage/swap.png"
        />
        <LandingPageContentSection
          ref={tradingPairsSectionRef}
          Title="Set trading pairs for price optimisation and incentives"
          SubTitle="Liquidity pool activity adjusts exchange rates through GaiaSwap’s constant market-making algorithm, generates trading fees for liquidity providers and promotes further trades"
          backgroundColor="#F9F9F9"
          imageURL="/images/landingpage/pairs.png"
        />
        <LandingPageContentSection
          ref={tokenDiscoverySectionRef}
          Title="Explore and discover tokens by underlying price attributes"
          SubTitle="GaiaSwap’s price optimisation feature reflects an eco-commodity’s fair market value and accounts for its unique attributes and co-benefits such as environmental, social and other Sustainable Development Goal (SDG) impacts"
          backgroundColor="#FFF"
          imageURL="/images/landingpage/discovery.png"
        />
        <SignupSection />
        <AboutFooter />
      </PageContainer>
    </>
  );
}
