import { Trans } from "@lingui/macro";
import { useAccountDrawer } from "components/AccountDrawer";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import { ReactNode, useCallback, useState } from "react";
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { useIsMobile } from "nft/hooks";
import { BREAKPOINTS } from "theme";

import * as styles from "./styles.css";

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 100px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding: ${({ theme }) => `${theme.navVerticalPad}px 20px`};
  }
`;

const BurgerMenuIcon = styled.div`
  width: 30px;
  height: 3px;
  background-color: black;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: black;
    left: 0;
  }

  &::before {
    top: -10px;
  }

  &::after {
    top: 10px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  margin-right: 12px;
  align-items: center;
  cursor: pointer;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 20;
`;

const CloseIcon = styled.div`
  align-self: flex-end;
  cursor: pointer;
  font-size: 24px;
`;

const MenuItemStyled = styled.div`
  padding: 15px 0; /* Add padding for each menu item */
  border-bottom: 1px solid #eff3ff;
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
  appButton?: boolean;
}

const MenuItem = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
  appButton,
}: MenuItemProps) => {
  // Define the base style
  const baseStyle = {
    textDecoration: "none",
  };

  const additionalStyles = appButton
    ? {
        borderRadius: "40px",
        background: "#C2E950",
        paddingLeft: "16px",
        paddingRight: "16px",
      }
    : {};

  const combinedStyle = {
    ...baseStyle,
    ...additionalStyles,
  };

  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={combinedStyle}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

const PageTabs = () => {
  return (
    <>
      <MenuItem href="/?section=swapSection">
        <Trans>Token Trade</Trans>
      </MenuItem>
      <MenuItem href="/?section=poolsSections">
        <Trans>Liquidity Pools</Trans>
      </MenuItem>
      <MenuItem href="/?section=discoverySection">
        <Trans>Price Discovery</Trans>
      </MenuItem>
      {/* <MenuItem appButton href="/swap">
        <Trans>Launch App</Trans>
      </MenuItem> */}
    </>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer();

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer();
    }
    navigate({
      pathname: "/",
      search: "?intro=true",
    });
  }, [accountDrawerOpen, navigate, toggleAccountDrawer]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box onClick={handleUniIconClick}>
              <img
                src="/images/logos/GaiaSwap-logo_Black.png"
                width="100%"
                height="25"
                data-testid="uniswap-logo"
                className={styles.logo}
              />
            </Box>
            {isMobile && <BurgerMenuIcon onClick={toggleMenu} />}
          </Box>
          {isMobile ? (
            menuOpen && (
              <MobileMenu>
                <CloseIcon onClick={toggleMenu}>X</CloseIcon>
                <MenuItemStyled onClick={toggleMenu}>
                  <MenuItem href="">Token Trade</MenuItem>
                </MenuItemStyled>
                <MenuItemStyled onClick={toggleMenu}>
                  <MenuItem href="/">Liquidity Pools</MenuItem>
                </MenuItemStyled>
                <MenuItemStyled onClick={toggleMenu}>
                  <MenuItem href="">Price Discovery</MenuItem>
                </MenuItemStyled>
                {/* <MenuItemStyled onClick={toggleMenu}>
                  <MenuItem appButton href="/swap">Launch App</MenuItem>
                </MenuItemStyled> */}
              </MobileMenu>
            )
          ) : (
            <Box className={styles.rightSideContainer}>
              <Row gap="12">
                <PageTabs />
              </Row>
            </Box>
          )}
        </Box>
      </Nav>
    </>
  );
};

export default Navbar;
