import { useState } from "react";
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useIsMobile } from "nft/hooks";
import { FaBars, FaTimes } from "react-icons/fa";
import { useAccountDrawer } from "components/AccountDrawer";
import Web3Status from "components/Web3Status";
import { chainIdToBackendName } from "graphql/data/util";
import { useIsPoolsPage } from "hooks/useIsPoolsPage";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import { ReactNode, useCallback } from "react";
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { ChainSelector } from "./ChainSelector";
import * as styles from "./style.css";

interface MobileMenuItemProps {
  children: React.ReactNode;
  href: string;
  onClick: (path: string) => void;
}

const Nav = styled.nav`
  background: #193553;
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`;

const MobileMenuItems = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FullScreenMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 30, 41, 0.98);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
}

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  children,
  href,
  onClick,
}) => {
  return (
    <MobileMenuItems
      style={{ cursor: "pointer" }}
      onClick={() => onClick(href)}
    >
      {children}
    </MobileMenuItems>
  );
};

const MenuItem = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
}: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{
        textDecoration: "none",
        color: "white",
        justifyContent: "center",
        fontSize: "14px",
      }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

const PageTabs = () => {
  const { pathname } = useLocation();
  const { chainId: connectedChainId } = useWeb3React();
  const chainName = chainIdToBackendName(connectedChainId);

  const isPoolActive = useIsPoolsPage();

  return (
    <>
      <MenuItem
        href="/marketplace"
        isActive={pathname.startsWith("/marketplace")}
      >
        <Trans>Market</Trans>
      </MenuItem>

      <MenuItem href="/swap" isActive={pathname.startsWith("/swap")}>
        <Trans>Token Trade</Trans>
      </MenuItem>
      <MenuItem
        href="/pools"
        dataTestId="pool-nav-link"
        isActive={isPoolActive}
      >
        <Trans>Liquidity Pools</Trans>
      </MenuItem>
    </>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleMobileMenuItemClick = (path: string) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer();
    }
    navigate({
      pathname: "/",
      search: "?intro=true",
    });
  }, [accountDrawerOpen, navigate, toggleAccountDrawer]);

  return (
    <>
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap" paddingLeft='50' paddingRight='50'>
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <img
                src="/images/logos/logo-light.png"
                width="130"
                height="25"
                data-testid="uniswap-logo"
                className={styles.logo}
                onClick={handleUniIconClick}
              />
            </Box>
            <Row display={{ sm: "none", lg: "flex" }}></Row>
          </Box>

          {isMobile ? (
            <div style={{ marginLeft: "auto" }} onClick={toggleMenu}>
              {isMenuOpen ? (
                <FaTimes color="white" />
              ) : (
                <FaBars color="white" />
              )}
            </div>
          ) : (
            <>
              <Box
                style={{ display: "flex" }}
                className={styles.searchContainer}
              >
                <PageTabs />
              </Box>
              <Box className={styles.rightSideContainer}>
                <Row gap="12">
                  <Box display={{ sm: "none", lg: "flex" }}>
                    <ChainSelector />
                  </Box>
                  <Web3Status />
                </Row>
              </Box>
            </>
          )}
        </Box>
      </Nav>

      {/* Fullscreen Menu for Mobile */}
      {isMobile && (
        <FullScreenMenu isOpen={isMenuOpen}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start", // Align items to the start (left)
              alignItems: "center", // Align items vertically in the center
              color: "white", // Set text color to white for all items in this div
              width: "100%", // Ensure the div takes the full width
              padding: "20px", // Add some padding around the items for spacing from the edges
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <img src="/images/logos/Gaia-Logo.png" />
            </div>{" "}
            {/* Logo takes up maximum space */}
            <FaTimes
              style={{
                cursor: "pointer",
                color: "white", // Ensure the icon is white
                marginLeft: "auto", // Pushes the icon to the far right
              }}
              onClick={toggleMenu}
            />
          </div>
          <div
            style={{
              border: "1px solid rgb(59, 62, 75)",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              color: "white",
              width: "100%",
              padding: "20px",
            }}
          >
            <MobileMenuItem
              href="/marketplace"
              onClick={handleMobileMenuItemClick}
            >
              Market
            </MobileMenuItem>
            <MobileMenuItem href="/swap" onClick={handleMobileMenuItemClick}>
              Token Trade
            </MobileMenuItem>
            <MobileMenuItem href="/pools" onClick={handleMobileMenuItemClick}>
              Liquidity Pools
            </MobileMenuItem>
          </div>
          <div
            style={{
              border: "1px solid rgb(59, 62, 75)",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              color: "white",
              width: "100%",
              padding: "20px",
              paddingRight: "75%",
            }}
          >
            <Web3Status />
          </div>
        </FullScreenMenu>
      )}
    </>
  );
};

export default Navbar;
