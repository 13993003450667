import React, { useEffect } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  background: white;
  padding: 32px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputGroup = styled.div`
  flex: 1;
  margin-right: 10px;
  margin-bottom: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
`;

const SubmitButton = styled.button`
  background-color: #000;
  color: rgb(206, 250, 64);
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  width: 33%;
`;

const Title = styled.h2`
  text-align: left;
`;

const SubTitle = styled.h3`
  text-align: left;
`;

const MailchimpForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  useEffect(() => {
    // Load Mailchimp script
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    // Define the function from the script
    script.onload = () => {
      (function ($: any) {
        window.fnames = [];
        window.ftypes = [];
        window.fnames[2] = "FNAME";
        window.ftypes[2] = "text";
        window.fnames[1] = "MMERGE1";
        window.ftypes[1] = "text";
        window.fnames[4] = "PHONE";
        window.ftypes[4] = "phone";
        window.fnames[6] = "DESIGNATIO";
        window.ftypes[6] = "text";
        window.fnames[7] = "COMPANY";
        window.ftypes[7] = "text";
        window.fnames[0] = "EMAIL";
        window.ftypes[0] = "email";
        window.fnames[3] = "ADDRESS";
        window.ftypes[3] = "address";
      })(window.jQuery);

      window.$mcj = window.jQuery.noConflict(true);
    };

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const data = new URLSearchParams(formData as any).toString();

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });

      const responseText = await response.text();
      console.log('Response text:', responseText);

      if (response.ok) {
        alert('Signup successful!');
        onClose();
      } else {
        console.error('Mailchimp API response error:', response.status, response.statusText, responseText);
        alert('Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <ModalContent>
      <Title>Tell us more about you</Title>
      <SubTitle>All fields are required.</SubTitle>
      <Form
        action="https://ecostructholdings.us21.list-manage.com/subscribe/post?u=74d5092e88463128ebf041950&amp;id=dadabc8d79&amp;f_id=006e9ce6f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        noValidate
        // onSubmit={handleSubmit}
      >
        <InputRow>
          <InputGroup>
            <Label htmlFor="mce-FNAME">First Name</Label>
            <Input
              id="mce-FNAME"
              name="FNAME"
              type="text"
              placeholder="First Name"
              required
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="mce-MMERGE1">Last Name</Label>
            <Input
              id="mce-MMERGE1"
              name="MMERGE1"
              type="text"
              placeholder="Last Name"
              required
            />
          </InputGroup>
        </InputRow>
        <InputGroup>
          <Label htmlFor="mce-DESIGNATIO">Designation</Label>
          <Input
            id="mce-DESIGNATIO"
            name="DESIGNATIO"
            type="text"
            placeholder="Designation"
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="mce-COMPANY">Company Name</Label>
          <Input
            id="mce-COMPANY"
            name="COMPANY"
            type="text"
            placeholder="Company Name"
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="mce-EMAIL">Work Email</Label>
          <Input
            id="mce-EMAIL"
            name="EMAIL"
            type="email"
            placeholder="Work Email"
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="mce-PHONE">Contact Number</Label>
          <Input
            id="mce-PHONE"
            name="PHONE"
            type="tel"
            placeholder="Contact Number"
            required
          />
        </InputGroup>
        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
    </ModalContent>
  );
};

export default MailchimpForm;
