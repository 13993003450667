import {
  BrowserEvent,
  InterfaceElementName,
  SharedEventName,
} from "@uniswap/analytics-events";
import { TraceEvent } from "analytics";
import { useDisableNFTRoutes } from "hooks/useDisableNFTRoutes";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";
import { ExternalLink, StyledRouterLink } from "theme/components";
import { useIsDarkMode } from "theme/components/ThemeToggle";

import { DiscordIcon, GithubIcon, TwitterIcon } from "./Icons";
import darkUnicornImgSrc from "./images/unicornEmbossDark.png";
import lightUnicornImgSrc from "./images/unicornEmbossLight.png";

const Wrapper = styled.div`
  width: 100%;
  background: #193553;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  padding: 80px;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/images/landingpage/footer.jpeg") lightgray -3.507px -230.649px /
      100% 228.216% no-repeat;
    opacity: 0.2;
    z-index: -1;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoSectionLeft = styled(LogoSection)`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    display: flex;
    align-items: center;
  }
`;

const LogoSectionBottom = styled(LogoSection)`
  display: flex;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 115px;
  height: 22px;
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    display: block;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0 0 0;
`;

const SocialLink = styled.a`
  display: flex;
`;

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    grid-template-columns: 1fr;
    text-align: center;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 200px;
  margin: 20px 0 0 0;
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    margin: 0;
    width: 200px;
  }
`;

const LinkGroupTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 535;
  color: white;
  text-align: left;
`;

const ExternalTextLink = styled(ExternalLink)`
  font-size: 14px;
  line-height: 20px;
  color: #d8dcec;
  text-align: left;
`;

const TextLink = styled(StyledRouterLink)`
  font-size: 14px;
  line-height: 20px;
  color: #d8dcec;
  text-align: left;
`;

const Copyright = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin: 1rem 0 0 0;
  color: ${({ theme }) => theme.white};
`;

const LogoSectionContent = () => {
  return (
    <>
      <StyledLogo
        src="/images/logos/Gaiaswap-Logo_Default_Light.png"
        alt="Gaiswap Logo"
      />
    </>
  );
};

export const AboutFooter = () => {
  return (
    <Wrapper>
      <Footer>
        <LogoSectionLeft>
          <LogoSectionContent />
        </LogoSectionLeft>

        <FooterLinks>
          <LinkGroup>
            <LinkGroupTitle>Contact us</LinkGroupTitle>
            <ExternalTextLink href="mailto:info@gaiaswap.io">
              info@gaiaswap.io
            </ExternalTextLink>
          </LinkGroup>
          <LinkGroup>
            <LinkGroupTitle>Get in touch</LinkGroupTitle>
            <ExternalTextLink
              target="_blank"
              href="https://www.linkedin.com/company/gaiaswap/"
            >
              LinkedIn
            </ExternalTextLink>
          </LinkGroup>
          <LinkGroup>
            <LinkGroupTitle>Terms of Use</LinkGroupTitle>
            <ExternalTextLink
              target="_blank"
              href="https://drive.google.com/file/d/1Cwn3GmDvDdYhDKm4o6wQnzJ_N85HdeIK/view?usp=sharing"
            >
              Terms &#38; Conditions
            </ExternalTextLink>
            <ExternalTextLink
              target="_blank"
              href="https://drive.google.com/file/d/1steLFJnWR0Jixmey1QkySlnwrOk-G-rl/view?usp=sharing"
            >
              Privacy Policy
            </ExternalTextLink>
          </LinkGroup>
          <LinkGroup>
            <LinkGroupTitle>©2024 GaiaSwap</LinkGroupTitle>
          </LinkGroup>
        </FooterLinks>

        <LogoSectionBottom>
          <LogoSectionContent />
        </LogoSectionBottom>
      </Footer>
    </Wrapper>
  );
};
