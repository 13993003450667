// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import {
  InterfaceEventName,
  InterfaceModalName,
} from "@uniswap/analytics-events";
import { ChainId, Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { Trace } from "analytics";
import { useCachedPortfolioBalancesQuery } from "components/PrefetchBalancesWrapper/PrefetchBalancesWrapper";
import { supportedChainIdFromGQLChain } from "graphql/data/util";
import useDebounce from "hooks/useDebounce";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useToggle from "hooks/useToggle";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { getTokenFilter } from "lib/hooks/useTokenList/filtering";
import {
  TokenBalances,
  tokenComparator,
  useSortTokensByQuery,
} from "lib/hooks/useTokenList/sorting";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Text } from "rebass";
import styled, { useTheme } from "styled-components";
import { CloseIcon, ThemedText } from "theme/components";
import { UserAddedToken } from "types/tokens";
import { splitHiddenTokens } from "utils/splitHiddenTokens";

import {
  useDefaultActiveTokens,
  useIsUserAddedToken,
  useSearchInactiveTokenLists,
  useToken,
} from "../../hooks/Tokens";
import { isAddress } from "../../utils";
import Column from "../Column";
import Row, { RowBetween } from "../Row";
import CommonBases from "./CommonBases";
import { CurrencyRow, formatAnalyticsEventProperties } from "./CurrencyList";
import CurrencyList from "./CurrencyList";
import { PaddedColumn, SearchInput, Separator } from "./styled";

const ContentWrapper = styled(Column)`
  background-color: ${({ theme }) => theme.surface1};
  width: 100%;
  overflow: hidden;
  flex: 1 1;
  position: relative;
  border-radius: 20px;
`;

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  onlyShowCurrenciesWithBalance?: boolean;
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance,
}: CurrencySearchProps) {
  const { chainId, account } = useWeb3React();
  const theme = useTheme();

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false);

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);
  const isAddressSearch = isAddress(debouncedQuery);
  const searchToken = useToken(debouncedQuery);
  const searchTokenIsAdded = useIsUserAddedToken(searchToken);

  const defaultTokens = useDefaultActiveTokens(chainId);

  const { data, loading: balancesAreLoading } = useCachedPortfolioBalancesQuery(
    { account }
  );
  const balances: TokenBalances = useMemo(() => {
    return (
      data?.portfolios?.[0].tokenBalances?.reduce(
        (balanceMap, tokenBalance) => {
          if (
            tokenBalance.token?.chain &&
            supportedChainIdFromGQLChain(tokenBalance.token?.chain) ===
              chainId &&
            tokenBalance.token?.address !== undefined &&
            tokenBalance.denominatedValue?.value !== undefined
          ) {
            const address =
              tokenBalance.token?.standard === "ERC20"
                ? tokenBalance.token?.address?.toLowerCase()
                : "ETH";
            const usdValue = tokenBalance.denominatedValue?.value;
            const balance = tokenBalance.quantity;
            balanceMap[address] = { usdValue, balance: balance ?? 0 };
          }
          return balanceMap;
        },
        {} as TokenBalances
      ) ?? {}
    );
  }, [chainId, data?.portfolios]);

  const sortedTokens: Token[] = useMemo(() => {
    const portfolioTokenBalances = data?.portfolios?.[0].tokenBalances;
    const portfolioTokens = splitHiddenTokens(portfolioTokenBalances ?? [])
      .visibleTokens.map((tokenBalance) => {
        if (
          !tokenBalance?.token?.chain ||
          !tokenBalance.token?.address ||
          !tokenBalance.token?.decimals
        ) {
          return undefined;
        }

        const tokenChainId =
          supportedChainIdFromGQLChain(tokenBalance.token?.chain) ??
          ChainId.MAINNET;
        if (tokenChainId !== chainId) {
          return undefined;
        }

        const portfolioToken = new Token(
          tokenChainId,
          tokenBalance.token?.address,
          tokenBalance.token?.decimals,
          tokenBalance.token?.symbol,
          tokenBalance.token?.name
        );

        return portfolioToken;
      })
      .filter((token) => !!token) as Token[];

    const filteredTokens = Object.values(defaultTokens)
      .filter(getTokenFilter(debouncedQuery))
      // Filter out tokens with balances so they aren't duplicated when we merge below.
      .filter((token) => !(token.address?.toLowerCase() in balances));
    const mergedTokens = [...(portfolioTokens ?? []), ...filteredTokens];

    if (balancesAreLoading) {
      return mergedTokens;
    }

    return mergedTokens
      .filter((token) => {
        if (onlyShowCurrenciesWithBalance) {
          return balances[token.address?.toLowerCase()]?.usdValue > 0;
        }

        // If there is no query, filter out unselected user-added tokens with no balance.
        if (!debouncedQuery && token instanceof UserAddedToken) {
          if (
            selectedCurrency?.equals(token) ||
            otherSelectedCurrency?.equals(token)
          )
            return true;
          return balances[token.address.toLowerCase()]?.usdValue > 0;
        }
        return true;
      })
      .sort(tokenComparator.bind(null, balances));
  }, [
    data,
    defaultTokens,
    debouncedQuery,
    balancesAreLoading,
    balances,
    chainId,
    onlyShowCurrenciesWithBalance,
    selectedCurrency,
    otherSelectedCurrency,
  ]);

  const isLoading = Boolean(balancesAreLoading && !tokenLoaderTimerElapsed);

  const filteredSortedTokens = useSortTokensByQuery(
    debouncedQuery,
    sortedTokens
  );

  const native = useNativeCurrency(chainId);
  const wrapped = native.wrapped;

  const searchCurrencies: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim();

    const tokens = filteredSortedTokens.filter(
      (t) => !(t.equals(wrapped) || (disableNonToken && t.isNative))
    );

    const shouldShowWrapped =
      !onlyShowCurrenciesWithBalance ||
      (!balancesAreLoading && balances[wrapped.address]?.usdValue > 0);
    const natives = (
      disableNonToken || native.equals(wrapped)
        ? [wrapped]
        : shouldShowWrapped
        ? [native, wrapped]
        : [native]
    ).filter(
      (n) =>
        n.symbol?.toLowerCase()?.indexOf(s) !== -1 ||
        n.name?.toLowerCase()?.indexOf(s) !== -1
    );

    // manually add THB to the list
    const additionalToken = new Token(
      11155111, // chainId
      "0x25Ac3cDa55ee6e0cCF39D06a7cd311834e2FF19f", // token address
      18, // decimals
      "THB", // symbol
      "THB" // name
    );

    return [...natives, ...tokens, additionalToken];
  }, [
    debouncedQuery,
    filteredSortedTokens,
    onlyShowCurrenciesWithBalance,
    balancesAreLoading,
    balances,
    wrapped,
    disableNonToken,
    native,
  ]);

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning);
      if (!hasWarning) onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === native?.symbol?.toLowerCase()) {
          handleCurrencySelect(native);
        } else if (searchCurrencies.length > 0) {
          if (
            searchCurrencies[0].symbol?.toLowerCase() ===
              debouncedQuery.trim().toLowerCase() ||
            searchCurrencies.length === 1
          ) {
            handleCurrencySelect(searchCurrencies[0]);
          }
        }
      }
    },
    [debouncedQuery, native, searchCurrencies, handleCurrencySelect]
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    !onlyShowCurrenciesWithBalance &&
      (sortedTokens.length === 0 ||
        (debouncedQuery.length > 2 && !isAddressSearch))
      ? debouncedQuery
      : undefined
  );

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true);
    }, 3000);
    return () => clearTimeout(tokenLoaderTimer);
  }, []);

  return (
    <ContentWrapper>
      <Trace
        name={InterfaceEventName.TOKEN_SELECTOR_OPENED}
        modal={InterfaceModalName.TOKEN_SELECTOR}
        shouldLogImpression
      >
        <PaddedColumn gap="16px">
          <RowBetween>
            <Text fontWeight={535} fontSize={16}>
              <Trans>Select a token</Trans>
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <Row>
            <SearchInput
              type="text"
              id="token-search-input"
              data-testid="token-search-input"
              placeholder={t`Search name or paste address`}
              autoComplete="off"
              value={searchQuery}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />
          </Row>
          {showCommonBases && (
            <CommonBases
              chainId={chainId}
              onSelect={handleCurrencySelect}
              selectedCurrency={selectedCurrency}
              searchQuery={searchQuery}
              isAddressSearch={isAddressSearch}
            />
          )}
        </PaddedColumn>
        <Separator />
        {searchToken && !searchTokenIsAdded ? (
          <Column style={{ padding: "20px 0", height: "100%" }}>
            <CurrencyRow
              currency={searchToken}
              isSelected={Boolean(
                searchToken &&
                  selectedCurrency &&
                  selectedCurrency.equals(searchToken)
              )}
              onSelect={(hasWarning: boolean) =>
                searchToken && handleCurrencySelect(searchToken, hasWarning)
              }
              otherSelected={Boolean(
                searchToken &&
                  otherSelectedCurrency &&
                  otherSelectedCurrency.equals(searchToken)
              )}
              showCurrencyAmount={showCurrencyAmount}
              eventProperties={formatAnalyticsEventProperties(
                searchToken,
                0,
                [searchToken],
                searchQuery,
                isAddressSearch
              )}
              balance={
                tryParseCurrencyAmount(
                  String(
                    balances[
                      searchToken.isNative
                        ? "ETH"
                        : searchToken.address?.toLowerCase()
                    ]?.balance ?? 0
                  ),
                  searchToken
                ) ?? CurrencyAmount.fromRawAmount(searchToken, 0)
              }
            />
          </Column>
        ) : searchCurrencies?.length > 0 ||
          filteredInactiveTokens?.length > 0 ||
          isLoading ? (
          <div style={{ flex: "1" }}>
            <AutoSizer disableWidth>
              {({ height }) => (
                <CurrencyList
                  height={height}
                  currencies={searchCurrencies}
                  otherListTokens={filteredInactiveTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  fixedListRef={fixedList}
                  showCurrencyAmount={showCurrencyAmount}
                  isLoading={isLoading}
                  searchQuery={searchQuery}
                  isAddressSearch={isAddressSearch}
                  balances={balances}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <Column style={{ padding: "20px", height: "100%" }}>
            <ThemedText.DeprecatedMain
              color={theme.neutral3}
              textAlign="center"
              mb="20px"
            >
              <Trans>No results found.</Trans>
            </ThemedText.DeprecatedMain>
          </Column>
        )}
      </Trace>
    </ContentWrapper>
  );
}
