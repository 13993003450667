import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "theme";

interface ThreeColumnContentItem {
  imageURL: string;
  Title: string;
  Subtitle: string;
  learnMoreURL: string;
}

interface LandingPageContentSectionProps {
  Title: string;
  SubTitle: string;
  imageURL?: string;
  threeColumnContent?: ThreeColumnContentItem[];
  backgroundColor: string;
  id?: string;
}

const Section = styled.section<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  padding: 80px 0;
  text-align: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    width: 270px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 635px;
  }
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    width: 900px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 550;
  color: #1A1E29;
  color: var(--Gray-Gray-900, #1a1e29);
  align-self: center;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 18px;
    margin-bottom: 40px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 32px;
  }
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 36px;
  }
`;

const Text = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: var(--Gray-Gray-900, #1a1e29);
  align-self: center;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
  }
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 18px;
  }
`;

const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  color: var(--Gray-Gray-800, #3b3e4b);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 100px;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    margin: 0 0 40px;
    height: auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 16px;
  }
`;

const StyledAnchorTag = styled.a`
  border-radius: 40px;
  border: 1px solid var(--Gray-Gray-500, #969aa8);
  text-decoration: none;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 14px;
    color: var(--Gray-Gray-800, #3b3e4b);
    padding-left: 12px;
    padding-right: 12px;
    width: fit-content;
    align-self: center;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
  }
`;

const ImageContainer = styled.div`
  width: 720px;
  height: 302.29px;
  margin: 0 auto;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    width: 270px;
    height: auto;
  }
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: auto;
    height: auto;
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 80%;
    height: auto;
    margin-top: 30px;
  }
`;

const ThreeColumnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: calc(33.333% - 20px);
  margin: 10px;
  box-sizing: border-box;
  height: 100%;

  img {
    height: auto;
    align-self: center;

    @media screen and (min-width: ${BREAKPOINTS.xs}px) {
      width: 100px;
    }

    @media screen and (min-width: ${BREAKPOINTS.md}px) {
      width: 125px;
    }
  }

  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    max-width: 100%;
    margin-top: 45px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    max-width: calc(33.333% - 20px);
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    margin: 0px 20px 20px;
  }
`;

const ColumnSubtitle = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    height: 125px;
  }
`;

const Header = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const LandingPageContentSection = React.forwardRef<
  HTMLDivElement,
  LandingPageContentSectionProps
>(
  (
    {
      Title: title,
      SubTitle: subTitle,
      imageURL,
      threeColumnContent,
      backgroundColor,
    },
    ref
  ) => {
    return (
      <Section ref={ref} backgroundColor={backgroundColor}>
        <ContentContainer>
          <Header>
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
          </Header>
          {!threeColumnContent ? (
            <ImageContainer>
              <img style={{ maxWidth: "100%" }} src={imageURL} alt="" />
            </ImageContainer>
          ) : (
            <ThreeColumnWrapper>
              {threeColumnContent.map((item, index) => (
                <Column key={index}>
                  <img src={item.imageURL} alt="" />
                  <Text>{item.Title}</Text>
                  <ColumnSubtitle>
                    <SubTitle>{item.Subtitle}</SubTitle>
                  </ColumnSubtitle>
                  <StyledAnchorTag href={item.learnMoreURL}>
                    Learn More
                  </StyledAnchorTag>
                </Column>
              ))}
            </ThreeColumnWrapper>
          )}
        </ContentContainer>
      </Section>
    );
  }
);

export default LandingPageContentSection;
